/**************************************/
/* GLOBAL */
/**************************************/
body {
    margin: 0px;
    font-family: Helvetica;
    background-color: #f2f3f3;
}

span {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
}

.midway-login {
    padding-left: 20px;
    padding-top: 20px;
}

/**************************************/
/* NAVBAR */
/**************************************/
.logo {
    padding-left: 6px;
    padding-top: 5px;
    width: 30px;
    height: 30px;
}

.navbar-dropdown {
    padding-bottom: 11px;
    transform: rotate(180deg);
}

.navbar-dropdown-button {
    float: right;
    background-color: transparent;
    stroke: transparent;
    border: none;
    padding-right: 30px;
    width: 10px;
}

.navbar-dropdown-flipped {
    padding-top: 11px;
}

.navbar-label {
    color: #d5dbdb;
    font-size: 12px;
    height: 42px;
    padding-top: 13px;
    padding-bottom: 0px;
}

.navbar-patching-button {
    color: #d5dbdb;
    background-color: #232F3E;
    border: 1px solid #d5dbdb;
    border-radius: 2px;
    margin-top: 10px;
    margin-left: 285px;
    height: 20px;
    position: absolute;
    font-size: 12px;
}

.navbar-pvri {
    background: #232F3E;
    width: 100%;
    height: 42px;
    min-width: 625px;
    position: fixed;
    z-index: 100;
}

.navbar-sign-in {
    float: right;
    padding-right: 12px;
}

.navbar-title {
    padding-left: 6px;
    display: inline-block;
    position: absolute;
}

/**************************************/
/* MAIN VIEW */
/**************************************/

.btn-invert {
    margin-left: 5px;
    height: 23px;
    font-size: 11px;
    margin-top: 5px;
    margin-right: 8px;
}

.btn-invert:focus {
    margin-left: 5px;
    height: 23px;
    font-size: 11px;
    margin-top: 5px;
    margin-right: 8px;
}

.btn-refresh {
    margin-top: 5px;
    margin-right: 15px;
    height: 23px;
    float: right;
}

.btn-sort {
    vertical-align: -4px;
    padding-left: 10px;
}

.content {
    width: 1500px;
    overflow-x: hidden;
    max-width: 100%;
}

.main-view {
    background-color: #f2f3f3;
    max-width: 100%;
    min-width: 965px;
}

.radio-text {
    vertical-align: middle;
    padding-right: 8px;
    padding-left: 3px;
}

.refresh-logo {
    padding-top: 1px;
    width: 15px;
    height: 15px;
}

.searchbar {
    margin-right: 10px;
    height: 18px;
    border-radius: 4px;
    border-style: inset;
    border-radius: 4px;
    border-width: 1.5px;
    background-color: #FFFFFF;
}

.sort-buttons {
    padding-left: 25px;
    padding-bottom: 15px;
    padding-top: 6px;
    height: 20px;
    width: 1600px;
    position: fixed;
    background-color: #f2f3f3;
    z-index: 11;
    font-size: 11px;
    max-width: 100%;
    min-width: 1350px;
}

.sync-text {
    color: #6C7778;
    padding-top: 5px;
    font-size: 11px;
    float: right;
    padding-right: 40px;
}

.sync-label {
    display: block;
    text-align: right;
}

/**************************************/
/* TABLE */
/**************************************/
/* Statuses */
.active {
    color: #94ED88;
}

.pre_release {
    color: #36C2B4;
}

.deprecating {
    color: #FFC400;
}

.deprecated {
    color: #FF7676;
}

.retired {
    -webkit-text-stroke: 0.1px black;
    color: #D5DBDB;
}

.recalled {
    -webkit-text-stroke: 0.1px black;
    color: #FFCFCF;
}

.unknown_platform {
    -webkit-text-stroke: 0.1px black;
    color: #FFCFCF;
}

/* Column Types */

.pv-name {
    width: 135px;
}

.pv-family {
    width: 60px;
}

.pv-version {
    width: 40px;
}

.pv-revision {
    width: 40px;
}

.pv-provider {
    width: 70px;
}

.pv-agent {
    width: 75px;
}

.pv-container {
    width: 130px;
}

.pv-ami-id {
    width: 125px;
}

.pv-ami-button {
    color: #387bab;
    font-size: 10px;
    padding: 0px;
    border: 0;
    background-color: #FFFFFF;
}

.pv-region-name {
    width: 75px;
}

.pv-internal {
    width: 60px;
	padding-right: 0px;
}

.pv-region-code {
    width: 70px;
}

.pv-region-arrow {
    width: 20px;
}

.pv-traffic-weight {
    width: 40px;
}

.pv-stage {
    width: 40px;
}

.pv-capabilities {
    width: 140px;
}

.pv-state {
    width: 80px;
}

/* Other */

.pv-table {
    background-color: #FFFFFF;
    border-radius: 5px;
    margin-left: 20px;
    padding-top: 10px;
    padding-left: 8px;
    padding-bottom: 10px;
}

.pv-table-column {
    text-align: left;
    vertical-align: top;
    padding-right: 10px;
    padding-bottom: 0px;
    padding-top: 7px;
    padding-left: 7px;
    height: 10px;
}

.pv-table-column-header {
    text-align: left;
    padding-right: 10px;
    padding-bottom: 0px;
    padding-top: 5px;
    padding-left: 7px;
    height: 25px;
}

.pv-table-row {
    border-top: 1px solid #f2f3f3;
}

.pv-table-row-header {
    border-bottom: 1px solid black;
    padding-top: 38px;
    background-color: #FFFFFF;
    width: 1480px;
    z-index: 10;
    position: fixed;
    max-width: 100%;
    min-width: 965px;
}

.pv-table-body {
    margin-top: 68px;
    min-width: 965px;
}

table {
    border-collapse: collapse;
    box-shadow: 0px 1px 1px #d9d9d9;
    font-size: 10px;
}

.triangle {
    max-width: 8px !important;
}

.triangle-expand {
    transform: rotate(180deg);
}

.triangle-collapse {
    vertical-align: top;
}

.inline-div {
    display: inline-block;
}

/**************************************/
/* METADATA */
/**************************************/
.metadata-button {
    font-size: 11px;
    margin-left: 16px;
    margin-top: -2px;
    padding-top: 3px;
    padding-bottom: 3px;
    position: fixed;
    z-index: 11;
}

.metadata-bar {
    background-color: #f2f3f3;
    position: fixed;
    z-index: 10;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid black;
}

.metadata-title {
    background-color: #f2f3f3;
    padding-left: 120px;
    margin-top: 10px;
}

.metadata-body {
    padding-left: 16px;
    padding-top: 10px;
    white-space: break-spaces;
    font-size: 11px;
}

.metadata-header {
    font-size: 16px;
    padding-top: 10px;
}

.metadata-row {
    border-top: 1px solid #f2f3f3;
    height: 22px;
}

.metadata-column {
    padding-left: 10px;
}

.container-table {
    background-color: #FFFFFF;
    border-radius: 5px;
    padding-top: 10px;
    padding-left: 8px;
    padding-bottom: 10px;
    margin-bottom: 30px;
    margin-top: 10px;
}

.container-table-header {
    background-color: #FFFFFF;
}

.container-ref {
    width: 470px;
}

.container-type {
    width: 270px;
}

.container-digest {
    width: 430px;
}

.container-size {
    width: 60px;
}

.container-platforms {
    width: 80px;
}

.container-labels {
    width: 80px;
}

.package-table {
    background-color: #FFFFFF;
    border-radius: 5px;
    padding-top: 10px;
    padding-left: 8px;
    padding-bottom: 10px;
    margin-top: 10px;
}

.package-name {
    width: 250px;
}

.package-installed {
    width: 75px;
}

/**************************************/
/* PATCHING DASHBOARD */
/**************************************/

.patching-bar {
    background-color: #f2f3f3;
    position: fixed;
    z-index: 10;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid black;
}

.patching-body {
    padding-left: 16px;
    padding-top: 10px;
    white-space: break-spaces;
    font-size: 20px;
}

.patching-loading {
    padding-right: 10px;
    font-size: 14px;
}

.patching-title {
    background-color: #f2f3f3;
    padding-left: 20px;
    margin-top: 10px;
}

.file-upload {
    float: right;
    margin-right: 15px;
}
